<template>
<div>
  <template v-if="!findstart">
    <div>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" sm="8" md="6">
            <template>
              <div class="responsive-image-wrapper">
                <v-img
                  src="@/assets/findgil-logo.png"
                  class="responsive-image"
                ></v-img>
              </div>
            </template>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" sm="8" md="6">
            <v-card class="elevation-3 pa-6">
              <h1 class="mb-4">뭐 먹지?!  ...</h1>
              <v-text-field
                v-model="keywords"
                label="지금 생각나는 단어 아무거나 하나만 입력해 주세요."
                outlined
                dense
              ></v-text-field>
              <h3 class="mb-4">예) 비, 따뜻, 시원, 궁물, 간단, 연인, 데이트, 저렴, 다이어트, 포만감, 간식, 맥주 등 </h3>
              <v-btn @click="startFindFood" color="primary">Start</v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template> 
  <template v-else>  
    <flow-form
        ref="flowform"
        v-on:complete="onComplete"
        v-on:submit="onSubmit"
        v-bind:questions="questions"
        v-bind:language="language"
        v-bind:standalone="true"      
      >
      <!-- Custom content for the Complete/Submit screen slots in the FlowForm component -->
        <!-- We've overriden the default "complete" slot content -->
        <template v-slot:complete>
          <p>
            <span class="fh2">Thank you!! 오늘 뭘 먹을지 볼까요?</span>
            <span v-if="!submitted" class="f-section-text">
              "결과보기" 버튼을 클릭하시거나 처음부터 다시 시작하고 싶으시면 "Enter" 버튼을 클릭해 주세요.
            </span>
          </p>
        </template>
        <!-- We've overriden the default "completeButton" slot content -->
        <template v-slot:completeButton>
          <div class="f-submit" v-if="!submitted">
            <button
              class="o-btn-action"
              ref="button"
              type="submit"
              href="#"
              v-on:click.prevent="onSubmit()"
              aria-label="Press to submit">
              <span>결과보기</span>
            </button>            
            <a 
              class="f-enter-desc"
              href="#"
              v-on:click.prevent="goHome()"
              v-html="language.formatString(language.pressEnter)">
            </a>
          </div>
        </template>
    </flow-form>
  </template> 
  <template>
    <div v-if="isResultVisible">
      <v-container>
        <v-card class="mx-auto my-5" max-width="500">
          <v-card-title>{{ recipe.RCP_TTL }}</v-card-title>
          <v-card-subtitle>{{ recipe.CKG_NM }}</v-card-subtitle>
          <v-card-text>
            <div class="stats-container">
              <v-chip class="ma-2" color="blue lighten-4" text-color="blue">
                조회수: {{ recipe.INQ_CNT }}
              </v-chip>
              <v-chip class="ma-2" color="green lighten-4" text-color="green">
                추천수: {{ recipe.RCMM_CNT }}
              </v-chip>
              <v-chip class="ma-2" color="orange lighten-4" text-color="orange">
                스크랩수: {{ recipe.SRAP_CNT }}
              </v-chip>
            </div>
            <div class="mb-4">
              <div><strong>요리 방법:</strong> {{ recipe.CKG_MTH_ACTO_NM }}</div>
              <div><strong>요리 종류:</strong> {{ recipe.CKG_KND_ACTO_NM }}</div>
              <div><strong>주재료:</strong> {{ recipe.CKG_MTRL_ACTO_NM }}</div>
            </div>
            <div><strong>인분:</strong> {{ recipe.CKG_INBUN_NM }}</div>
            <div><strong>대상:</strong> {{ recipe.CKG_DODF_NM }}</div>
            <div><strong>소요 시간:</strong> {{ recipe.CKG_TIME_NM }}</div>
            <div class="mb-4">
              <div><strong>요리 설명:</strong> {{ recipe.CKG_IPDC }}</div>
            </div>
            <div>
              <strong>재료 정보:</strong>
              <ul>
                <li v-for="(ingredient, index) in parsedIngredients" :key="index">
                  
                    {{ ingredient }}
                
                </li>
              </ul>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="isNextFoodVisible" color="primary" @click="getNextFood">다른 음식보여줘</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </div>
</template>

<template>
  <v-container>
    <!-- Dialog 정의 -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="headline">오늘의 쿠팡 딜!</v-card-title>
        <v-card-text>
          <p>쿠팡 방문하고 24시간 광고제거하기</p>          
          <!-- 광고 이미지를 링크로 감싸기 -->
          <a :href="adLink" target="_blank" @click="closeDialog">
            <v-img
              src="https://image7.coupangcdn.com/image/coupang/common/logo_coupang_w350.png"
              alt="쿠팡 이미지"
              max-height="300"
            ></v-img>
          </a>
          <p></p>
          <p>이 포스팅은 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를 제공받습니다.</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>




</div>
</template>

<script>
import FlowForm from '../components/FlowForm.vue'

import QuestionModel, { QuestionType, ChoiceOption, LinkOption } from '../models/QuestionModel'
import LanguageModel from '../models/LanguageModel'
import InfiniteLoading from 'vue-infinite-loading'
import moment from 'moment'
//import { mapGetters } from 'vuex'
import FindFoodService from "@/services/FindFoodService";

export default {
  name: 'FindFood',
  components: {
    // InfiniteLoading,
    //RecipeCard,
    FlowForm
    
  },
  computed: {
    parsedIngredients() {
      return this.recipe.CKG_MTRL_CN.split('|').map(ingredient => ingredient.trim());
    }    
  },
  data: () => ({
    findfoodlist: [],
    resultfoodlist: [],
    foodidx: 0,
    isNextFoodVisible: false,
    isResultVisible: false,
    dialog: false,
    adLink: 'https://link.coupang.com/a/bAvj7u', // 광고 링크 URL
    keywords: '',
    findstart: false,
    loading: false,
    loaded: false,
    errored: false,
    foodlist: [],
    page: 1,
    submitted: false,
    completed: false,
    score: 0, 
    total: 8, 
    time: 0,
    formattedTime: '',
    sentences: [
      '마음에 드는 단어가 들어간 문장을 모두 선택해 보세요',
      '어떤 문장이 끌리시나요?',
      '문장이 좀 길지만, 이중에 마음이 가는게 있으세요?',
      '거의 다 왔습니다. 계속해서 선택해 주세요.',
      '자~ 이번엔 조리법이예요~! 음식을 어떻게 조리하는게 좋으세요?'     
    ],
    waytocook: [
      //'끓이기',
      '볶음',
      //'굽기',
      '기타',
      //'무침',
      '부침',
      '조림',
      //'찜',
      '튀김',
      //'절임',
      //'비빔',
      '삶기'
      //'데치기',
      //'회'
    ],
    language: new LanguageModel(),
    // Create question list with QuestionModel instances
    questions: [],
    recipe: {
        RCP_SNO: '6347647',
        RCP_TTL: '누구나 좋아하는 소시지 야채볶음',
        CKG_NM: '소시지야채볶음',
        INQ_CNT: 23001,
        RCMM_CNT: 8,
        SRAP_CNT: 341,
        CKG_MTH_ACTO_NM: '볶음',
        CKG_STA_ACTO_NM: '술안주',
        CKG_MTRL_ACTO_NM: '가공식품류',
        CKG_KND_ACTO_NM: '밑반찬',
        CKG_IPDC: '야채들을 아삭하게 볶아서 탱탱한 소시지와 함께 먹으면 참말로 맛나요. 맥주를 절로 부르는 음식이지요~',        
        CKG_MTRL_CN: '[재료] 수제소시지 3개| 양파 1/2개| 빨강파프리카 1/4개| 노랑파프리카 1/4개| 주황파프리카 1/4개| 페페론치노 4개 [양념] 포도씨유 적당량 | 후춧가루 약간 | 토마토케찹 2Ts| 스테이크소스 1Ts| 스위트칠리소스 1Ts| 넛멕파우더 약간| 파슬리가루 약간| 소금 약간',
        CKG_INBUN_NM: '제한없음',
        CKG_DODF_NM: '아무나',
        CKG_TIME_NM: '30분이내'
      }
  }),
  
  mounted() {
    document.addEventListener('keyup', this.onKeyListener)
  },

  beforeUnmount() {
    document.removeEventListener('keyup', this.onKeyListener)
  },
  methods: {
    onKeyListener($event) {
      // We've overriden the default "complete" slot so
      // we need to implement the "keyup" listener manually.

      //if ($event.key === 'Enter' && this.completed && !this.submitted) {
      if ($event.key === 'Enter') {
        this.startFindFood() 
      }
    },

    closeDialog() {
      this.dialog = false;
    },

    goHome() {
      this.$router.push('/');
    },

    async getFoodList($state) {
      if (!this.loaded) {
        this.loading = true
      }
      const foods = await FindFoodService.getByName('부추')         
      if (typeof foods === 'undefined') return
      
      if (foods.data.length) {
        this.page += 1
        this.makeList(foods, 10)
        $state.loaded()
        this.loaded = true
        this.loading = false;
      } else {
        $state.complete()
      }
    },
    makeList(jsonDataArray, optCnt) {
        //console.log(JSON.stringify(jsonDataArray))
        console.log('LENGTH-' + jsonDataArray.data.length)
        //for (let i = 0; i < jsonDataArray.length; i++) {
        for (let i = 0; i < optCnt; i++) {
          const item = this.getItemByLength(jsonDataArray, 100);
          console.log('!!!' + item.CKG_IPDC)
          const mappedItem = {
              _id: item.RCP_SNO,
              userId: 'april',
              title: item.CKG_NM,
              createdAt:'20230801',
              description: item.CKG_IPDC, 
              value: item.CKG_MTH_ACTO_NM + '/' + item.CKG_STA_ACTO_NM
          };
          this.foodlist.push(mappedItem);
        }
      },

      getItemByLength(jsonDataArray, maxlen) {
        const maxNumber = jsonDataArray.data.length;
        for(;;){
          let randomNumber = Math.floor(Math.random() * maxNumber);
          let item = jsonDataArray.data[randomNumber]; 
          if(item.CKG_IPDC.length >(maxlen/2) && item.CKG_IPDC.length <= maxlen) return item;
          //console.log('Too Long!!!-' + item.CKG_IPDC)
        }
      },
      createQuestionbyDesc(qid, jsonDataArray, optCnt) {
        //console.log(JSON.stringify(jsonDataArray))
        console.log('LENGTH-' + jsonDataArray.data.length)
        let options = [];
        //for (let i = 0; i < jsonDataArray.length; i++) {
        for (let i = 0; i < optCnt; i++) {
          const item = this.getItemByLength(jsonDataArray, 30 + (30*qid));
          console.log('!!!' + item.CKG_IPDC)
          const mappedItem =new ChoiceOption({
              label: item.CKG_IPDC, 
              value: item.CKG_MTH_ACTO_NM + '/' + item.CKG_STA_ACTO_NM
          });
          options.push(mappedItem);
        }
        
        const question = new QuestionModel({
          id: 'qa_' + qid,
          title: this.sentences[qid],
          helpText: 'Choose all answers that apply.',
          type: QuestionType.MultipleChoice,
          required: true,
          multiple: true,
          options: options
        });

        return question;
      },

      async startFindFood() {
        console.log('----------START FIND WORDS ---------->' + this.keywords)
        //const foods = await FindFoodService.getByContents(this.keywords)  
        this.findfoodlist = await FindFoodService.getByContents(this.keywords)      
        if (typeof this.findfoodlist === 'undefined') return
       
        let i = 0;
        for (i = 0; i < 4; i++) {
          this.questions[i] = this.createQuestionbyDesc(i, this.findfoodlist, 2); // 옵션개수
        }
        this.questions[i] =this.choiceWaytoCook(i);
        this.findstart = true;
      },

      choiceWaytoCook(qid) {
        let options = [];
        //for (let i = 0; i < jsonDataArray.length; i++) {
        for (let i = 0; i < this.waytocook.length; i++) {
          const mappedItem =new ChoiceOption({
              label: this.waytocook[i],
              value: this.waytocook[i]
          });
          options.push(mappedItem);
        }
        
        const question = new QuestionModel({
          id: 'co_' + qid,
          title: this.sentences[qid],
          helpText: 'Choose all answers that apply.',
          type: QuestionType.MultipleChoice,
          required: true,
          multiple: true,
          options: options
        });

        return question;
      },


      onComplete(completed, questionList) {
        // This method is called whenever the "completed" status is changed.
        this.completed = completed
      },
      
      seeAnswer() {
        const methodCount = {};
        const typeCount = {};

        this.dialog = true;
        
        this.questions.forEach(question => {
          if (question.type !== QuestionType.SectionBreak) {
            console.log(question.answer);
            const answer = String(question.answer);

            if (answer && answer !== "undefined" && answer.includes('/')) {
              console.log(answer);
        
              const parts = answer.split('/');
              
              const method = parts[0];
              const type = parts[1] || 'undefined'; // 'type'이 undefined인 경우를 처리합니다.

              console.log('method:' + method);
              console.log('type:' + type);
              if (method) {
                methodCount[method] = (methodCount[method] || 0) + 1;
              }

              if (type) {
                typeCount[type] = (typeCount[type] || 0) + 1;
              }
            }
          }
        });

        const mostCommonMethod = Object.keys(methodCount).reduce((a, b) => methodCount[a] > methodCount[b] ? a : b, "");
        const mostCommonType = Object.keys(typeCount).reduce((a, b) => typeCount[a] > typeCount[b] ? a : b, "");

        console.log(`선택된 조리 방법: ${mostCommonMethod}`);
        console.log(`선택된 요리 종류: ${mostCommonType}`);
        
        this.searchList(this.findfoodlist, mostCommonMethod, mostCommonType);
        if (this.resultfoodlist && this.resultfoodlist !== "undefined" && this.resultfoodlist.length >0) {
          console.log('RESULT LENGTH-' + this.resultfoodlist.length);  
          this.recipe = this.resultfoodlist[this.foodidx++];    
          if(this.resultfoodlist.length > 1) this.isNextFoodVisible = true;
        }

        this. isResultVisible = true;
      },

      searchList(jsonDataArray, mostCommonMethod, mostCommonType) {
        console.log(mostCommonMethod + "/"  + mostCommonType)
        //console.log(JSON.stringify(jsonDataArray))
        console.log('LENGTH-' + jsonDataArray.data.length)       
        //for (let i = 0; i < jsonDataArray.length; i++) {
        for (let i = 0; i < jsonDataArray.data.length; i++) {
          const item = this.getItemBySearchItem(jsonDataArray, mostCommonMethod, mostCommonType);
          console.log('Name' + item.CKG_NM)
          console.log('CKG_MTH_ACTO_NM' + item.CKG_MTH_ACTO_NM)
          console.log('CKG_STA_ACTO_NM' + item.CKG_STA_ACTO_NM)
          this.resultfoodlist.push(item);          
        }
      },

      getItemBySearchItem(jsonDataArray, mostCommonMethod, mostCommonType) {
        const maxNumber = jsonDataArray.data.length;
        for(;;){
          let randomNumber = Math.floor(Math.random() * maxNumber);
          let item = jsonDataArray.data[randomNumber]; 
          if(item.CKG_MTH_ACTO_NM == mostCommonMethod && item.CKG_STA_ACTO_NM == mostCommonType) return item;          
        }
      },

      getNextFood() {
        if (this.resultfoodlist.length > this.foodidx) {
          console.log(this.foodidx + "/" + this.resultfoodlist.length);  
          this.recipe = this.resultfoodlist[this.foodidx++];  
          if(this.resultfoodlist.length == this.foodidx) this.isNextFoodVisible = false;      
        }
      },
      
      onSubmit() {
        console.log('----------Query Data ---------------')
        // Set `submitted` to true so the form knows not to allow back/forward
        // navigation anymore.
        this.$refs.flowform.submitted = true
        
        this.submitted = true
        this.seeAnswer();

        //this.calculateScore()
      },
      truncateText(string = '', num) {
        if (string.length <= num) {
          return string
        }
        return string.slice(0, num)
      },
      dateFormatter(date) {
        return moment(date).fromNow()
      }
  }
  
}
</script>

<style lang="css">
  @import '../../src/assets/css/themes/theme-purple.css';
  /* If using the npm package, use the following lines instead of the one above */
  /* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css'; */
  /* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-purple.css'; */
</style>


<style lang="scss">
.responsive-image-wrapper {
  display: flex;
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
  padding-top: 1px; /* 위에 여분의 공간 */
  padding-bottom: 1px; /* 아래에 여분의 공간 */
  width: 50%; /* 부모 요소에 꽉 차게 */
}

.responsive-image {
  display: block;
  width: 100%;
  height: 200px; /* 이미지 원본 비율 유지 */
}
</style>

<style lang="scss">
  .v-skeleton-loader__list-item-avatar-three-line {
  height: 250px;
  .v-skeleton-loader__paragraph.v-skeleton-loader__bone {
    margin-top: -125px;
    @media (max-width: 700px) {
      display: none;
      margin-top: inherit;
    }
  }
  .v-skeleton-loader__avatar {
    height: 200px !important;
    width: 300px !important;
  }
  .v-skeleton-loader__text {
    height: 20px;
  }
}
</style>

